<template>
  <v-container grid-list-xl>
    <v-layout wrap justify-space-between>
      <v-flex xs12 md12>
        <v-alert :value="alert" type="success">Başarıyla kaldedildi.</v-alert>
      </v-flex>
      <v-flex xs12 md12>
        <v-text-field v-model="detail.id" label="Id" disabled></v-text-field>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="startDate"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="startDate" label="Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="startDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(startDate)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="4">
            <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :return-value.sync="endDate"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="endDate" label="End Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="endDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu2 = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu2.save(endDate)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-switch v-model="detail.isActive" :label="`Is Active`" hide-details></v-switch>
        <v-text-field v-model="detail.backgroundPicture" label="Background Picture"></v-text-field>
        <v-text-field v-model="detail.rewards[0].id" label="Id" disabled></v-text-field>
        <v-text-field v-model="detail.rewards[0].targetXp" label="Target XP" hide-details></v-text-field>
        <v-text-field v-model="detail.rewards[0].free.id" label="Rewards Free Description Id" disabled></v-text-field>
        <v-text-field v-model="detail.rewards[0].free.description" label="Rewards Free Description"
          hide-details></v-text-field>
        <v-autocomplete v-model="detail.rewards[0].free.rewardType" label="Rewards Free Reward Type" :counter="max"
          :items="rewardType" item-value="key" item-text="value" placeholder="Select..."></v-autocomplete>
        <v-text-field v-model="detail.rewards[0].free.value" label="Rewards Free Value" hide-details></v-text-field>
        <v-text-field v-model="detail.rewards[0].premium.id" label="Rewards Premium Description Id"
          disabled></v-text-field>
        <v-text-field v-model="detail.rewards[0].premium.description" label="Rewards Premium Description"
          hide-details></v-text-field>
        <v-autocomplete v-model="detail.rewards[0].premium.rewardType" label="Rewards Premium Reward Type" :counter="max"
          :items="rewardType" item-value="key" item-text="value" placeholder="Select..."></v-autocomplete>
        <v-text-field v-model="detail.rewards[0].premium.value" label="Rewards Premium Value" hide-details></v-text-field>
        <v-text-field v-model="detail.bigReward.id" label="Rewards Big Reward Id" disabled></v-text-field>
        <v-text-field v-model="detail.bigReward.description" label="Big Reward Description" hide-details></v-text-field>
        <v-autocomplete v-model="detail.bigReward.rewardType" label="Big Reward Reward Type" :counter="max"
          :items="rewardType" item-value="key" item-text="value" placeholder="Select..."></v-autocomplete>
        <v-text-field v-model="detail.bigReward.value" label="Big Reward Value" hide-details></v-text-field>
      </v-flex>
      <v-btn color="success" @click="update">Update</v-btn>
    </v-layout>
  </v-container>
</template>
<script>
import rewardType from "@/lib/rewardType";
import router from "@/router";
export default {
  data: () => ({
    detail: {},
    rewardType: rewardType,
    alert: false,
    max: 120,
    menu: false,
    modal: false,
    menu2: false,
    startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
  }),

  methods: {
    async update() {
      (this.detail.startTime =
        new Date(
          new Date(this.startDate).getTime() +
          new Date().getTimezoneOffset() * 60000
        ) / 1000),
        (this.detail.endTime =
          new Date(
            new Date(this.endDate).getTime() +
            new Date().getTimezoneOffset() * 60000
          ) / 1000),
        await this.$store.dispatch("seasonPass/put", this.detail);
      router.push("/seasonPass");
    },
    async initialize() {
      if (this.$route.params.id) {
        await this.$store.dispatch("seasonPass/getById", this.$route.params.id);
        this.detail = this.$store.state.seasonPass.detail;
        this.startDate = new Date(this.detail.startTime * 1000)
          .addDays(1)
          .toISOString()
          .split("T")[0];
        this.endDate = new Date(this.detail.endTime * 1000)
          .addDays(1)
          .toISOString()
          .split("T")[0];
        // eslint-disable-next-line no-console
        console.log(this.startDate);
        // eslint-disable-next-line no-console
        console.log(this.endDate);
      }
    },
  },
  validateField() {
    this.$refs.form.validate();
  },
  async mounted() {
    await this.initialize();
  },
};
</script>
